"use client";

import "../styles.css";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLogin } from "@/src/lib/api/useLogin";
import { resetRegisterState, setPhoneNumber } from "@/src/redux/slices/register_slice";
import { EmailInput } from "@/src/components/(auth)/email_input";
import { PhoneNumberInput } from "@/src/components/(auth)/phone_input";
import useFirebaseServices from "@/firebase/firbaseServices";
import { PhoneOtpInput } from "@/src/components/(auth)/phone_otp";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "@/firebase/firebaseApp";
interface AuthOptionsProviderProps {
  authType: "login" | "register";
  initialStage: "email" | "phone" | "google";
}

export const AuthOptionsProvider = ({ authType, initialStage }: AuthOptionsProviderProps) => {
  const dispatch = useDispatch();
  const { error, checkUserExists, handleCallbackUrl, login } = useLogin();

  const router = useRouter();
  const searchParams = useSearchParams();
  const callback: string = searchParams?.get("callback") || "";
  const mobileRef = searchParams?.get("mobileRef") || "";
  const ref = searchParams?.get("ref") || "";

  // ------- EMAIL REGISTRATION -------
  const [currentStage, setCurrentStage] = useState<"email" | "phone" | "otp" | "google">(initialStage); // email, phone, otp
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailLoading, setIsEmailLoading] = useState(false);

  // ------- PHONE REGISTRATION -------
  const {
    requestOTP,
    verifyOTP,
    isLoading: phoneIsLoading,
    firebaseError,
    linkEmailPassword,
    handleReset,
  } = useFirebaseServices();
  const [phoneInput, setPhoneInput] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const formatPhoneNumber = (input: string) => {
    const digits = input.replace(/\D/g, "");
    if (digits.length === 0) return ""; // Add this line
    let formattedNumber = "";
    if (digits.length <= 3) {
      formattedNumber = `(${digits}`;
    } else if (digits.length <= 6) {
      formattedNumber = `(${digits.slice(0, 3)})-${digits.slice(3)}`;
    } else {
      formattedNumber = `(${digits.slice(0, 3)})-${digits.slice(3, 6)}-${digits.slice(6)}`;
    }
    return formattedNumber;
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const regex = /^\(\d{3}\)-\d{3}-\d{4}$/;
    return regex.test(phoneNumber);
  };

  const handlePhoneNumberChange = (phoneNumber: string) => {
    if (phoneNumber.length == 14) {
      if (!validatePhoneNumber(phoneNumber)) {
        // Phone number is invalid, show an error message
        setPhoneError("Invalid phone number format");
      } else {
        // Phone number is valid, clear the error message
        setPhoneError("");
      }
    } else {
      setPhoneError("");
    }
  };

  const handleRequestOTP = () => {
    const phoneNumberOtp = `+1${phoneInput.replace(/\D+/g, "")}`;
    dispatch(setPhoneNumber(phoneNumberOtp));
    requestOTP(phoneNumberOtp).then(() => {
      setCurrentStage("otp");
    });
  };

  // ------- OTP REGISTRATION -------
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimeout, setResendTimeout] = useState(10);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (resendTimeout > 0) {
        setResendTimeout(resendTimeout - 1);
      } else {
        setResendDisabled(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [resendTimeout]);

  const handleVerifyOTP = async () => {
    const otpString = otp.join("");

    async function handleCompletedExternalLogin() {
      const url = await handleCallbackUrl(atob(callback));
      if (url) {
        window.location.href = url;
      }
      setTimeout(() => {
        router.push("/return-to-app?callback=" + callback);
      }, 200);
    }

    try {
      const results = await verifyOTP(otpString);
      //console.log("results", results);

      if (firebaseError) {
        return;
      }

      if (!results) throw new Error("error verifying OTP");

      if (initialStage === "email") {
        const success = await linkEmailPassword(email, password);
        if (!success) throw new Error("error linking email password");
      }

      if (initialStage === "google") {
      }

      if (results?.userExists) {
        // TODO: ONBOARDING
        if (!results?.onboarding_complete) {
          // return
        }

        if (callback) {
          await handleCompletedExternalLogin();
        } else {
          router.push("/dashboard");
        }
      } else {
        const params = new URLSearchParams();
        if (callback) params.append("callback", callback);
        if (mobileRef) params.append("mobileRef", mobileRef);
        if (ref) params.append("ref", ref);

        const queryString = params.toString();
        router.push(`/register/set-username${queryString ? `?${queryString}` : ""}`);
      }
    } catch (error) {
      //console.log("FIREBASE ERROR", firebaseError);
      setOtpError(error instanceof Error ? error.message : "An unknown error occurred");
    }
  };

  const handleResendCode = async () => {
    try {
      if (!phoneInput) {
        console.error("Phone number not found.");
        return;
      }
      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        console.error("Recaptcha container not found.");
        return;
      }

      const recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainer,
        {
          size: "invisible", // or 'normal' if you want to see the reCAPTCHA
        },
        auth,
      );
      const cleanedPhoneNumber = "+1" + phoneInput.replace(/\D+/g, "");
      const confirmationResult = await signInWithPhoneNumber(auth, cleanedPhoneNumber, recaptchaVerifier);
      sessionStorage.setItem("verificationId", confirmationResult.verificationId);

      console.log("OTP resent successfully");
    } catch (error) {
      console.error("Error resending OTP:", error);
      setOtpError("Failed to resend OTP. Please try again.");
    }

    setResendDisabled(true);
    setResendTimeout(10);
    setOtp(Array(6).fill(""));
  };

  useEffect(() => {
    dispatch(resetRegisterState());
  }, []);

  const renderStage = () => {
    switch (currentStage) {
      case "email":
        return (
          <EmailInput
            email={email}
            referral={mobileRef || ref}
            authType={authType}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            onComplete={async () => {
              try {
                setIsEmailLoading(true);

                if (authType === "login") {
                  const response = await login(email, password, callback);
                  if (response?.exists) {
                    if (!response?.hasPhoneNumber) {
                      console.log("PHONE NUMBER NOT FOUND");
                      // User doesnt have a phone number
                      setCurrentStage("phone");
                      return;
                    } else {
                      // TODO handle onboarding
                      router.push("/dashboard");
                    }
                  } else {
                    throw new Error("Invalid email or password");
                    // User doesnt exist
                  }
                } else {
                  const userExists = await checkUserExists(email, password);
                  if (userExists) {
                    throw new Error("User already exists");
                  } else {
                    setCurrentStage("phone");
                  }
                }
              } catch (e) {
                console.error(e);
              } finally {
                setIsEmailLoading(false);
              }
            }}
            isLoading={isEmailLoading}
            error={error}
            loginAllowed={mobileRef ? false : true}
          />
        );
      case "phone":
        return (
          <PhoneNumberInput
            onComplete={() => handleRequestOTP()}
            onBack={() => {
              if (initialStage === "email") {
                setCurrentStage("email");
              } else {
                router.back();
              }
            }}
            referral={mobileRef || ref}
            phoneNumber={phoneInput}
            onPhoneNumberChange={(element: React.ChangeEvent<HTMLInputElement>) => {
              let formattedText = element.target.value.replace(/\+1/g, "");
              if (formattedText.startsWith("1")) {
                formattedText = formattedText.slice(1);
              } else if (formattedText.startsWith("(1")) {
                formattedText = formattedText.slice(2);
              }
              formattedText = formatPhoneNumber(formattedText);
              setPhoneInput(formattedText);
              handlePhoneNumberChange(formattedText);
            }}
            isLoading={phoneIsLoading}
            error={firebaseError || phoneError}
          />
        );
      case "otp":
        return (
          <PhoneOtpInput
            onBack={() => {
              setOtp(Array(6).fill(""));
              setResendDisabled(true);
              setResendTimeout(10);
              setOtpError("");
              handleReset();
              setCurrentStage("phone");
            }}
            referral={mobileRef || ref}
            phoneNumber={phoneInput}
            otp={otp}
            setOtp={setOtp}
            handleResendCode={handleResendCode}
            isLoading={phoneIsLoading}
            error={firebaseError || otpError}
            resendDisabled={resendDisabled}
            resendTimeout={resendTimeout}
            onComplete={handleVerifyOTP}
          />
        );

      default:
        return <div></div>;
    }
  };

  return <div className="login-layout__container">{renderStage()}</div>;
};
