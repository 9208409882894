import React, { Dispatch, SetStateAction } from "react";
import "../../app/(auth)/styles.css";
import { OtpInput } from "@/src/(deprecated)/old_login/linking/otp-code/page";
import CustomLoadingSpinner from "../custom_loading_spinner/custom_loading_spinner";
type PhoneOtpInputProps = {
  onComplete: () => void;
  phoneNumber: string;
  otp: string[];
  setOtp: Dispatch<SetStateAction<string[]>>;
  handleResendCode: () => void;
  isLoading: boolean;
  error: string;
  resendDisabled: boolean;
  resendTimeout: number;
  onBack: () => void;
  referral?: string;
};

export const PhoneOtpInput: React.FC<PhoneOtpInputProps> = ({
  onComplete,
  phoneNumber,
  otp,
  setOtp,
  handleResendCode,
  isLoading,
  error,
  resendDisabled,
  resendTimeout,
  onBack,
  referral,
}) => {
  return (
    <div>
      <div className="-mt-20 flex h-screen flex-col items-center justify-center">
        <div className="md:px-40 flex-col justify-center gap-y-3 font-sans">
          <h1 className="font-sfpro-heavy self-center text-4xl font-bold">We sent an OTP to</h1>
          <p className="font-sfpro-medium self-center text-md font-semibold text-gray-400">{phoneNumber}</p>
          {referral && <p className="font-sfpro-regular self-center text-sm text-[#666666]">Referral: {referral}</p>}
          {error && <p className="font-sfpro-medium self-center text-md font-semibold text-red">{error}</p>}
          <div className="flex flex-col space-y-4 self-center lg:w-[400px]">
            <OtpInput length={6} otp={otp} setOtp={setOtp} />
            <div className="flex w-full flex-col items-center justify-between">
              <button
                className="mt-2 flex w-[62%] flex-row items-center justify-center rounded-2xl border bg-black p-3 font-medium text-white"
                data-variant="black"
                data-size="thin"
                onClick={(e) => {
                  e.preventDefault();
                  onComplete();
                }}
                disabled={isLoading}
              >
                {isLoading ? <CustomLoadingSpinner spinnerColor="text-white" /> : "Verify"}
              </button>

              <button
                className={`mt-4 flex w-[62%] flex-row items-center justify-center rounded-2xl border border-gray-500 p-3 font-medium text-black ${resendDisabled ? "bg-gray-200 opacity-35" : ""}`}
                data-variant="black"
                data-size="thin"
                onClick={(e) => {
                  e.preventDefault();
                  handleResendCode();
                }}
                disabled={resendDisabled}
              >
                {resendDisabled ? `Resend in ${resendTimeout} seconds` : "Resend Code"}
              </button>
              <div id="recaptcha-container"></div>
              <button
                className="mt-4 flex w-[62%] flex-row items-center justify-center rounded-2xl border border-gray-300 p-3 font-medium text-black"
                data-variant="black"
                data-size="thin"
                onClick={(e) => {
                  e.preventDefault();
                  onBack();
                }}
                disabled={isLoading}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
