import React, { useState } from "react";
import Link from "next/link";
import "../../app/(auth)/styles.css";
import { Icon } from "@/src/core/icon";
import Image from "next/image";
type EmailInputProps = {
  onComplete: () => void;
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  isLoading: boolean;
  error: string;
  loginAllowed?: boolean;
  authType: "login" | "register";
  referral?: string;
};

export const EmailInput: React.FC<EmailInputProps> = ({
  onComplete,
  email,
  password,
  setEmail,
  setPassword,
  isLoading,
  error,
  loginAllowed = true,
  authType,
  referral,
}) => {
  const [clicked, setClicked] = useState({
    email: false,
    password: false,
  });

  return (
    <>
      <div className="md:px-40 my-auto space-y-12 font-sans">
        <div className="space-y-2 text-center">
          <Image
            src="/sellraze_blue_icon_rounded-xl.png"
            alt="SellRaze Logo"
            width={150} // specify the width
            height={150} // specify the height
            className="mx-auto"
          />
          <h1 className="font-sfpro-semibold text-center text-[32px] font-extrabold text-black">
            {authType === "login" ? "Welcome back to SellRaze" : "Welcome to SellRaze"}
          </h1>
          {/* Users being sent to /register w/ a referral link must register a new account*/}
          {loginAllowed && authType === "register" && (
            <p className="text-[16px] text-min-grey">
              Already have an account?{" "}
              <Link className="text-sellRazeBlue underline" href={"/login"}>
                Log in here.
              </Link>
            </p>
          )}
          {authType === "login" && (
            <p className="font-sfpro-regular self-center text-[14px] text-[#666666]">
              Don’t have an account?{" "}
              <Link className="font-sfpro-bold text-[14px] font-semibold text-sellRazeBlue" href={`/register`}>
                Register for free.
              </Link>
            </p>
          )}
          {referral && <p className="font-sfpro-regular self-center text-sm text-[#666666]">Referral: {referral}</p>}
        </div>
        <div className="space-y-4 lg:w-[420px]">
          <form className="flex flex-col gap-[1rem]">
            <input
              className={`w-full self-center rounded-xl bg-gray-100 p-4 text-[18px]`}
              type="email"
              id="text"
              name="email"
              placeholder="Email address"
              required
              value={email}
              onChange={(evt) => {
                setEmail(evt.target.value);
                setClicked((prev) => ({ ...prev, email: true }));
              }}
            />
            <input
              className={`w-full self-center rounded-xl bg-gray-100 p-4 text-[18px]`}
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              required
              value={password}
              onChange={(evt) => {
                setPassword(evt.target.value);
              }}
              onFocus={() => {
                setClicked((prev) => ({ ...prev, password: true }));
              }}
            />
          </form>
          {error && <span className="ml-4 text-red">{error}</span>}
          <button
            className={`${email.length == 0 || password.length == 0 ? "bg-gray-400" : "bg-sellRazeBlue"} w-full rounded-xl p-4 text-white`}
            data-variant="black"
            data-size="thin"
            disabled={email.length == 0 || password.length == 0 || isLoading}
            onClick={onComplete}
          >
            {isLoading ? (
              <Icon className="[ spinner ] text-sellRazeBlue" icon="loading" width="20" height="20" alt="loading..." />
            ) : (
              <p>Submit</p>
            )}
          </button>
        </div>
      </div>
      {authType === "register" && (
        <p className="absolute bottom-4 left-1/2 w-full -translate-x-1/2 px-4 text-center text-sm text-min-grey">
          By clicking &quot;Get Started&quot;, you agree to our{" "}
          <Link className="text-sellRazeBlue" href="/tos" target="doc">
            Terms
          </Link>{" "}
          and acknowledge that you have read and understand our{" "}
          <Link className="text-sellRazeBlue" href="/privacy" target="doc">
            Privacy Policy.
          </Link>
        </p>
      )}
    </>
  );
};
