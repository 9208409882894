import React from "react";
import "../../app/(auth)/styles.css";
import { Icon } from "@/src/core/icon";
type PhoneNumberInputProps = {
  onComplete: () => void;
  onBack: () => void;
  phoneNumber: string;
  onPhoneNumberChange: (phoneNumber: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  error: string;
  referral?: string;
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  phoneNumber,
  onPhoneNumberChange,
  onComplete,
  onBack,
  isLoading,
  error,
  referral,
}) => {
  return (
    <div className="-mt-20 flex h-screen items-center justify-center p-10">
      <div className="flex flex-col">
        <h1 className="font-sfpro-semibold text-center text-[32px] font-extrabold text-black">
          Enter your phone number
        </h1>
        <p className="font-sfpro-regular mt-2 self-center text-center text-[16px] text-[#666666]">
          We&apos;ll send you and OTP code to verify you&apos;re not a bot 🤖
        </p>
        {referral && <p className="font-sfpro-regular self-center pt-2 text-sm text-[#666666]">Referral: {referral}</p>}
        {error && <p className="self-center text-sm text-red">{error}</p>}
        <div className="mb-2 mt-6 w-full items-center">
          <input
            className="font-sfpro-medium w-full rounded-2xl py-2 text-center text-xl text-gray-700 lg:text-5xl"
            style={{ fontWeight: 600, border: "none", outline: "none" }}
            placeholder="(123) 456-750"
            required
            onChange={onPhoneNumberChange}
            value={phoneNumber}
            maxLength={14}
            type="tel"
          />
          {error && <p className="text-red-500">{error}</p>}
        </div>
        <div className="mt-4 flex w-full flex-row justify-between self-center px-4 lg:w-[60%]">
          <button
            className="w-[48%] rounded-2xl bg-gray-100 p-3.5 text-[14px] font-semibold"
            type="button"
            onClick={onBack}
          >
            Back
          </button>
          <button
            id="recaptcha-container"
            className="flex w-[48%] items-center justify-center rounded-2xl bg-black p-3.5 text-[14px] text-white"
            type="button"
            disabled={phoneNumber.length < 14}
            onClick={(e) => {
              e.preventDefault();
              onComplete();
            }}
          >
            {isLoading ? (
              <Icon className="[ spinner ] mr-2" icon="loading" width="20" height="20" alt="loading..." />
            ) : (
              <p className="">Send code</p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
